import logo from "../images/logo.png";
import logoWhite from "../images/logoWhite.png";
import background from "../images/background.jpg";
import p1 from "../images/p1.jpg";
import p2 from "../images/p2.jpg";
import p3 from "../images/p3.jpg";
import p4 from "../images/p4.jpg";
import p5 from "../images/p5.webp";
import lenders from "../images/lenders.jpg";
import Map from "../components/map/map";
import { useRef, useState } from "react";
import ContactUs from "../components/contactUs";
import "./Home.css";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";

export default function Home() {
  const [clicked, setClicked] = useState(false);
  const service = useRef(null);
  const insurance = useRef(null);
  const lender = useRef(null);
  const contact = useRef(null);
  const handleClick1 = () => {
    service.current?.scrollIntoView({ behavior: "smooth" });
    setClicked(false);
  };
  const handleClick2 = () => {
    insurance.current?.scrollIntoView({ behavior: "smooth" });
    setClicked(false);
  };
  const handleClick3 = () => {
    lender.current?.scrollIntoView({ behavior: "smooth" });
    setClicked(false);
  };
  const handleClick4 = () => {
    contact.current?.scrollIntoView({ behavior: "smooth" });
    setClicked(false);
  };

  return (
    <body>
      <div className="curtain">
        <div className="curtainWrapper">
          <div className="curtainPannel curtainPannelLeft"></div>
          <div className="curtainLogo">
            <img src={logoWhite} alt="logo" />
          </div>
          <div className="curtainPannel curtainPannelRight"></div>
        </div>
      </div>
      <div className="main">
        <div className="navBar">
          <div className="logoContainer">
            <img src={logo} alt="Wonder Four Finance Logo" />
          </div>
          <div
            className={clicked ? "buttonContainerActive" : "buttonContainer"}
          >
            <div className="closeBtn" onClick={() => setClicked(false)}>
              <FaTimes />
            </div>
            <div>
              <button className="navButton" onClick={handleClick1}>
                Service
              </button>
              <button className="navButton" onClick={handleClick2}>
                Insurance
              </button>
              <button className="navButton" onClick={handleClick3}>
                Lenders
              </button>
              <button className="navButton" onClick={handleClick4}>
                Contact
              </button>
            </div>
          </div>
          <div className="openNav" onClick={() => setClicked(!clicked)}>
            <HiMenu size={"2em"} />
          </div>
        </div>
        <div
          className="imageContainer"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="imageBackground">
            <div className="title">Wonder Four Finance</div>
            <div className="subTitle">Mortgage brokers you can trust</div>
            <div>
              <button className="startBtn" onClick={handleClick4}>
                Start Now
              </button>
            </div>
          </div>
        </div>
        <div className="contentContainer">
          <div ref={service} className="title">
            How We Can Help You
          </div>
          <div className="text">
            Wonder Four Finance is a leading mortgage and financial broker in
            Australia. We specialise in home loans for residential and
            commercial properties, for first-time home buyers, and
            owner-occupiers as well as for investors and developers. We also
            provide a variety of additional lending solutions, from car loans
            and personal loans through to complex business and development. 
            With unprecedented access to multiple financial institutions and
            loan products, we can ensure competitive rates and flexible lending
            structures at all times. 
          </div>

          {/* Paragraph 1 */}
          <div className="paragraphContainer">
            <div className="imageColumn">
              <img src={p1} alt="Purchase Property" />
            </div>
            <div className="textColumn">
              <div className="content">
                <div className="title">Purchase Property</div>
                <div className="text">
                  Whether you’re buying your first home or your investment
                  properties,we are passionate about helping clients achieve
                  their property dreams. We have extensive knowledge and
                  experience in this area to give you professional advice
                  on comprehensive and reliable loan structure that best meets
                  your goals and long term financial needs. We will source a
                  product that is suitable to your financial needs. We will do
                  all the hard work for you in order that you may make an
                  informed decision.
                </div>
                <div className="btnContainer">
                  <button className="startBtn" onClick={handleClick4}>
                    Start Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Paragraph 2 */}
          <div className="paragraphContainer2">
            <div className="imageColumn">
              <img src={p2} alt="Refinance Property" />
            </div>
            <div className="textColumn">
              <div className="content">
                <div className="title">Refinance Property</div>
                <div className="text">
                  The world is changing rapidly and so does the finance market.
                  You might have the optimum solution and lowest interest rate
                  when first set up your loan, but it’s likely that your needs
                  and the market will change throughout a period of time.
                  <br />
                  <br />
                   Getting a new mortgage to replace the original is called
                  refinancing. Refinancing is done to allow a borrower to obtain
                  a better interest term and rate. The first loan is paid off,
                  allowing the second loan to be created, instead of simply
                  making a new mortgage and throwing out the original mortgage.{" "}
                  <br />
                  <br />
                  We can help you restructure your existing loans to build your
                  property portfolio, consolidate debts, or simply find a better
                  fit for your circumstances.
                </div>
                <div className="btnContainer">
                  <button className="startBtn" onClick={handleClick4}>
                    Start Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Paragraph 3 */}
          <div ref={lender} className="paragraphContainer">
            <div className="imageColumn">
              <img src={p3} alt="Comercial Lending" />
            </div>
            <div className="textColumn">
              <div className="content">
                <div className="title">Commercial Lending</div>
                <div className="text">
                  Commercial property and business lending policies and interest
                  rates are rarely found on lender’s websites. Unlike
                  residential home loans, the pricing of a commercial loan can
                  be negotiated based on your background in business and the
                  security to be offered. If you need help to refinance or
                  purchase commercial property, just contact us today.  
                  <br />
                  <br />
                  We can help you find a car loan that’s right for your budget
                  and circumstances. From Secured Car Loans to Novated Leasing,
                  Chattel Mortgaging, Commercial Hire Purchases, Replacement
                  Loans, Personal Unsecured Loan.
                </div>
                <div className="btnContainer">
                  <button className="startBtn" onClick={handleClick4}>
                    Start Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Paragraph 4 */}
        <div ref={insurance} className="insuranceParagraph">
          <div className="frame">
            <div className="textColumn">
              <div className="content">
                <div className="insuranceTitle">Insurance</div>
                <div className="insuranceSubtitle">Get Covered Today</div>
                <div className="insuranceTitle1">Home&Contents Insurance</div>
                <div className="insuranceText">
                  We provide market leading insurance product from the largest
                  insurance provider to ensure your home and contents are well
                  protected when unforeseen incidents occur.
                  <br />
                  <br />
                  In a nutshell, home and contents insurance is financial
                  protection for the home you live in and the things in it.
                  <br />
                  <br />
                  Home building insurance protects the house that you live in
                  and its fixtures (things attached to the house). If the core
                  elements of a home (e.g. roof, walls, plumbing) are ever
                  damaged or destroyed, home insurance should cover the cost of
                  repair or replacement.
                  <br />
                  <br />
                  Contents insurance protects possessions within the house (e.g.
                  clothes, television, refrigerator). If you are renting, you
                  probably just need contents insurance, as your landlord will
                  have home building insurance over the investment property
                  you’re living in.
                </div>
                <div className="insuranceTitle1">Car Insurance</div>
                <div className="insuranceText">
                  We also provide one of the most comprehensive car insurance on
                  the market today to give a peace of mind in your every
                  driving.
                  <br />
                  <br />
                  Car insurance is an insurance policy that covers damage to
                  other people’s property or their car, harm to other people, or
                  damage to your own car, including accident and theft,
                  depending on the type of policy you have.
                </div>
              </div>
            </div>
            <div className="imageColumn">
              <img src={p4} alt="Insurance" />
            </div>
          </div>
        </div>
        {/* <div ref={lender}>
          <SlidePages />
        </div> */}
        {/* Lenders */}
        <div ref={lender} className="paragraphContainer">
          <div className="textColumn">
            <div className="content" style={{ backgroundColor: "white" }}>
              <div className="title" style={{ fontSize: 35 }}>
                Lenders
              </div>
              <div>
                <img src={lenders} alt="Lenders" width="100%" />
              </div>
            </div>
          </div>
        </div>

        {/* Paragraph 5 */}
        <div className="contactContainer">
          <div className="formColumn">
            <div ref={contact} className="title">
              Get In Touch
            </div>
            <div className="addressText">
              <p>Shop 1</p>
              <p>8 Help Street</p>
              <p>Chatswood, 2067</p>
              <p>Australia</p>
              <p>
                <a
                  style={{ color: "white" }}
                  href="mailto:info@wonder4finance.com.au"
                >
                  info@wonder4finance.com.au
                </a>
              </p>
            </div>
            <ContactUs />
          </div>
          <div className="imageColumn">
            <img id="p5" src={p5} alt="Get in Touch" />
          </div>
        </div>
        {/* Map Secssion */}
        <div>
          <Map />
        </div>
        {/* Footer Secssion */}
        <footer sytle={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              marginTop: 80,
              marginBottom: 40,
              textAlign: "center",
            }}
          >
            ©2023 by Wonder Four Finance Pty Ltd.
          </div>
        </footer>
      </div>
    </body>
  );
}
