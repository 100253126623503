import React from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "60vh",
};

const center = {
  lat: -33.795916570805716,
  lng: 151.17954099703056,
};

function MyComponent() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB0zCLzlOty8hQNlUtm-M2isJb0V2LmLgA",
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
      <MarkerF position={center}>
        <InfoWindowF position={center}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "bold" }}>
              Shop 1, 8 Help Street Chatswood, 2067 Australia
            </div>
            <div>
              <a href="https://goo.gl/maps/mv4HhUMagxKLEPuF9">Directions</a>
            </div>
          </div>
        </InfoWindowF>
      </MarkerF>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
